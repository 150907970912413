import Header from './components/Header'
import OrderForm from './containers/OrderForm'
import { useCallback, useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import { socket } from './context/socket'
import { connect } from 'react-redux'
import {
  setAppLanguages,
  setCurrency,
  setDataFile,
  setDomainData,
  updateTranslationItem,
} from './redux-store/actions'
import apiDirectory from './api/directory'
import store from './redux-store'
import { withTranslation } from 'react-i18next'
import axios from 'axios'
import apiCheckout from './api/checkout'

function App({ translations, updateTranslation, setFileData, i18n }) {
  axios.defaults.headers.common['Accept-Language'] = i18n.language
  const [currentTranslations, setCurrentTranslations] = useState(translations)

  const updatePageNumber = (jsonObject: {
    id: string | number
    pages: any
    page: number
    timePerPage: any
  }) => {
    if (
      jsonObject.id &&
      jsonObject.pages &&
      currentTranslations &&
      currentTranslations[jsonObject.id]
    ) {
      const newTranslation = { ...translations[jsonObject.id] }
      newTranslation.pages = jsonObject.pages
      newTranslation.page = jsonObject.page || 0
      newTranslation.timePerPage = jsonObject.timePerPage
      newTranslation.uploadProgress = 100

      if (
        currentTranslations &&
        newTranslation &&
        currentTranslations[jsonObject.id] &&
        currentTranslations[jsonObject.id].page !== newTranslation.page
      ) {
        updateTranslation(newTranslation)
      }
    }
  }

  const setDataFilePath = (path: any) => {
    setFileData(path)
  }

  useEffect(() => {
    /**
     * Detects pages
     */

    socket.off('dataFile', setDataFilePath).on('dataFile', setDataFilePath)
    return () => {
      socket.off('dataFile', setDataFilePath)
    }
  }, [])

  useEffect(() => {
    /**
     * Detects pages
     */

    socket.off('detect-status', updatePageNumber).on('detect-status', updatePageNumber)
    return () => {
      socket.off('detect-status', updatePageNumber)
    }
  }, [Object.keys(translations)])

  useEffect(() => {
    setCurrentTranslations(translations)
  }, [translations])

  useEffect(() => {
    getAppLanguages()
    getCurrency()
  }, [])

  const getAppLanguages = useCallback(() => {
    const host = window.location.host
    return apiDirectory.getAll(`/app_languages?filterDomain=${host}`, {}).then((res: any) => {
      if (!(res.response && res.response.data)) store.dispatch(setAppLanguages(res))
    })
  }, [apiDirectory, setAppLanguages])

  const getCurrency = useCallback(() => {
    return apiCheckout.getAll(`/get-domain-currency`, {}).then((res: any) => {
      if (res) {
        const data = res as CurrencyResponse
        if (!data.PaymentAccount?.Market?.Currency) return

        store.dispatch(
          setCurrency({
            name: data.PaymentAccount.Market?.Currency?.name,
            symbol: data.PaymentAccount.Market?.Currency?.symbol,
          })
        )
        store.dispatch(
          setDomainData({
            email: data.PaymentAccount?.Market?.email,
            phone: data.PaymentAccount?.Market?.phone,
            host: data.PaymentAccount?.Market?.email?.split('@')[1],
            MarketCheckoutBlocks: data.PaymentAccount?.Market?.MarketCheckoutBlocks,
          })
        )
      }
    })
  }, [apiCheckout, setCurrency])

  return (
    <div className="App">
      <Header />
      <OrderForm />
      <ToastContainer position={'top-right'} className={'notification-toast'} />
    </div>
  )
}

// @ts-ignore
const mapStateToProps = ({ translation: { items } }) => ({
  translations: items,
})
const mapDispatchToProps = (dispatch: (arg0: { type: string; payload: any }) => any) => ({
  updateTranslation: (data: any) => dispatch(updateTranslationItem(data)),
  setFileData: (data: any) => dispatch(setDataFile(data)),
})

type CurrencyResponse = {
  PaymentAccount?: {
    Market?: {
      Currency?: {
        name: string
        symbol: string
      }
      email: string
      phone: string
      MarketCheckoutBlocks?: { page: string; position: string; value: string }[]
    }
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(App))
